import React, { useContext, useState, useEffect } from "react";
import dayjs from "dayjs";

import css from "./UserToday.module.css";
import { ClinicContext } from "../../Context/ClinicContext";
import Head from "../../Utils/Head";
import { updateUserFirebase } from "../../Utils/firebaseClinic";

export const UserToday = () => {
  const { selectedPatient, terActsList } = useContext(ClinicContext);
  const [taskModal, setTaskModal] = useState(false);
  const [mindModal, setMindModal] = useState(false);
  const [mindEditModal, setMindEditModal] = useState(false);
  const [h05h07, seth05h07] = useState(new Array(7).fill(""));
  const [h07h09, seth07h09] = useState(new Array(7).fill(""));
  const [h09h11, seth09h11] = useState(new Array(7).fill(""));
  const [h11h13, seth11h13] = useState(new Array(7).fill(""));
  const [h13h15, seth13h15] = useState(new Array(7).fill(""));
  const [h15h17, seth15h17] = useState(new Array(7).fill(""));
  const [h17h19, seth17h19] = useState(new Array(7).fill(""));
  const [h19h21, seth19h21] = useState(new Array(7).fill(""));
  const [h21h23, seth21h23] = useState(new Array(7).fill(""));

  useEffect(() => {
    if (selectedPatient.schedule) {
      seth05h07(selectedPatient.schedule.h05h07);
      seth07h09(selectedPatient.schedule.h07h09);
      seth09h11(selectedPatient.schedule.h09h11);
      seth11h13(selectedPatient.schedule.h11h13);
      seth13h15(selectedPatient.schedule.h13h15);
      seth15h17(selectedPatient.schedule.h15h17);
      seth17h19(selectedPatient.schedule.h17h19);
      seth19h21(selectedPatient.schedule.h19h21);
      seth21h23(selectedPatient.schedule.h21h23);
    }
  }, [selectedPatient]);

  function updateTerAct(c) {
    selectedPatient.terAct = c;
    updateUserFirebase(selectedPatient);
  }

  if (selectedPatient) {
    return (
      <div className={css.container}>
        <Head title="Today" description={"User - Mairini"} />
        <section>
          <div className={css.card}>
            <h4>Agenda {dayjs().format("DD.MM.YY")}</h4>
            {selectedPatient.schedule && (
              <div className={css.scheduleGrid}>
                <div>
                  <h5>MANHÃ</h5>
                  <p
                    onClick={() => {
                      h05h07[dayjs().day()].title
                        ? setTaskModal(h05h07[dayjs().day()].title)
                        : setTaskModal("Início da manhã");
                    }}
                  >
                    {h05h07[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h07h09[dayjs().day()].title
                        ? setTaskModal(h07h09[dayjs().day()].title)
                        : setTaskModal("Meio da manhã");
                    }}
                  >
                    {h07h09[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h09h11[dayjs().day()].title
                        ? setTaskModal(h09h11[dayjs().day()].title)
                        : setTaskModal("Fim da manhã");
                    }}
                  >
                    {h09h11[dayjs().day()].title}
                  </p>
                </div>
                <div>
                  <h5>TARDE</h5>
                  <p
                    onClick={() => {
                      h11h13[dayjs().day()].title
                        ? setTaskModal(h11h13[dayjs().day()].title)
                        : setTaskModal("Início da tarde");
                    }}
                  >
                    {h11h13[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h13h15[dayjs().day()].title
                        ? setTaskModal(h13h15[dayjs().day()].title)
                        : setTaskModal("Meio da tarde");
                    }}
                  >
                    {h13h15[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h15h17[dayjs().day()].title
                        ? setTaskModal(h15h17[dayjs().day()].title)
                        : setTaskModal("Fim da tarde");
                    }}
                  >
                    {h15h17[dayjs().day()].title}
                  </p>
                </div>
                <div>
                  <h5>NOITE</h5>
                  <p
                    onClick={() => {
                      h17h19[dayjs().day()].title
                        ? setTaskModal(h17h19[dayjs().day()].title)
                        : setTaskModal("Início da noite");
                    }}
                  >
                    {h17h19[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h19h21[dayjs().day()].title
                        ? setTaskModal(h19h21[dayjs().day()].title)
                        : setTaskModal("Meio da noite");
                    }}
                  >
                    {h19h21[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h21h23[dayjs().day()].title
                        ? setTaskModal(h21h23[dayjs().day()].title)
                        : setTaskModal("Fim da noite");
                    }}
                  >
                    {h21h23[dayjs().day()].title}
                  </p>
                </div>
              </div>
            )}
          </div>

          {selectedPatient.terAct ? (
            <div className={css.terAct}>
              <div className={css.terActHead}>
                <h4>Atividade Terapêutica Recomendada</h4>
                <i
                  className={`material-icons materialIcon`}
                  onClick={() => setMindEditModal(true)}
                >
                  edit
                </i>
              </div>
              <div className={css.mindGrid} onClick={() => setMindModal(true)}>
                <div>
                  <p>{selectedPatient.terAct.title}</p>
                </div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className={css.card}>
              <h4>Atividade Terapêutica</h4>
              <div>
                <p>Nenhum TerAct selecionado para o usuário</p>
                <button
                  className="defaultButton"
                  onClick={() => setMindEditModal(true)}
                >
                  Add a TerAct
                </button>
              </div>
            </div>
          )}
        </section>

        {taskModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setTaskModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{taskModal + ` - ${dayjs().format("DD.MM.YY")}`}</h3>
                <footer className={css.modalFooter}>
                  <button
                    className="deleteButton"
                    onClick={(e) => {
                      setTaskModal(false);
                    }}
                  >
                    Fechar
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}
        {mindEditModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setMindEditModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody2}>
                <h3>TerActs</h3>
                <div>
                  <p
                    onClick={() => {
                      updateTerAct("");
                      setMindEditModal(false);
                    }}
                  >
                    Nenhuma atividade
                  </p>
                </div>
                <div>
                  {terActsList.map((c) => (
                    <p
                      key={c.id}
                      onClick={() => {
                        updateTerAct(c);
                        setMindEditModal(false);
                      }}
                    >
                      {c.title}
                    </p>
                  ))}
                </div>
                <footer className={css.modalFooter}>
                  <button
                    className="deleteButton"
                    onClick={(e) => {
                      setMindEditModal(false);
                    }}
                  >
                    Fechar
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}

        {mindModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setMindModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{selectedPatient.terAct.title}</h3>
                <div>{selectedPatient.terAct.content}</div>
                <footer className={css.modalFooter}>
                  <button
                    className="deleteButton"
                    onClick={(e) => {
                      setMindModal(false);
                    }}
                  >
                    Fechar
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./UserPage.module.css";
import Head from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";
import {
  getUserByIdFirebase,
  updateUserFirebase,
} from "../../Utils/firebaseClinic";
import { Input } from "../Forms/Input";

export const UserListProblems = () => {
  const { setSelectedPatient, selectedPatient } = useContext(ClinicContext);
  const [problem1, setProblem1] = useState("");
  const [problem2, setProblem2] = useState("");
  const [problem3, setProblem3] = useState("");
  const [problem4, setProblem4] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getUserByIdFirebase(params.id).then(function (result) {
      setSelectedPatient(result[0]);
    });
  }, []);

  useEffect(() => {
    if (selectedPatient) {
      selectedPatient.listProblems &&
        setProblem1(selectedPatient.listProblems[0]);
      selectedPatient.listProblems &&
        setProblem2(selectedPatient.listProblems[1]);
      selectedPatient.listProblems &&
        setProblem3(selectedPatient.listProblems[2]);
      selectedPatient.listProblems &&
        setProblem4(selectedPatient.listProblems[3]);
    }
  }, [selectedPatient]);

  function handleSubmit() {
    const listProblems = [problem1, problem2, problem3, problem4];
    selectedPatient.listProblems = listProblems;

    setSelectedPatient(selectedPatient);
    updateUserFirebase(selectedPatient);
    navigate(`/user/${selectedPatient.id}/today`);
  }

  return (
    <section>
      <Head title="User" description="User - Mairini" />
      <form>
        <h4>Lista de Problemas</h4>
        <Input
          type="text"
          name="problem1"
          placeholder="Problema 1"
          label="Problema 1"
          value={problem1}
          required
          className={css.inputText}
          onChange={(e) => {
            setProblem1(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="problem2"
          placeholder="Problema 2"
          label="Problema 2"
          value={problem2}
          required
          className={css.inputText}
          onChange={(e) => {
            setProblem2(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="problem3"
          placeholder="Problema 3"
          label="Problema 3"
          value={problem3}
          required
          className={css.inputText}
          onChange={(e) => {
            setProblem3(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="problem4"
          placeholder="Problema 4"
          label="Problema 4"
          value={problem4}
          required
          className={css.inputText}
          onChange={(e) => {
            setProblem4(e.target.value);
          }}
          autoComplete="off"
        />
        <div className={css.footer}>
          <button className="saveButton" onClick={() => handleSubmit()}>
            Salvar
          </button>
        </div>
      </form>
    </section>
  );
};

import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./UserPage.module.css";
import Head from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";
import {
  getUserByIdFirebase,
  updateUserFirebase,
} from "../../Utils/firebaseClinic";
import { Input } from "../Forms/Input";

export const UserListGoals = () => {
  const { setSelectedPatient, selectedPatient } = useContext(ClinicContext);
  const [goal1, setGoal1] = useState("");
  const [goal2, setGoal2] = useState("");
  const [goal3, setGoal3] = useState("");
  const [goal4, setGoal4] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getUserByIdFirebase(params.id).then(function (result) {
      setSelectedPatient(result[0]);
    });
  }, []);

  useEffect(() => {
    if (selectedPatient) {
      selectedPatient.listGoals && setGoal1(selectedPatient.listGoals[0]);
      selectedPatient.listGoals && setGoal2(selectedPatient.listGoals[1]);
      selectedPatient.listGoals && setGoal3(selectedPatient.listGoals[2]);
      selectedPatient.listGoals && setGoal4(selectedPatient.listGoals[3]);
    }
  }, [selectedPatient]);

  function handleSubmit() {
    const listGoals = [goal1, goal2, goal3, goal4];
    selectedPatient.listGoals = listGoals;

    setSelectedPatient(selectedPatient);
    updateUserFirebase(selectedPatient);
    navigate(`/user/${selectedPatient.id}/today`);
  }

  return (
    <section>
      <Head title="User" description="User - Mairini" />
      <form>
        <h4>Lista de Metas</h4>
        <Input
          type="text"
          name="goal1"
          placeholder="Meta 1"
          label="Meta 1"
          value={goal1}
          required
          className={css.inputText}
          onChange={(e) => {
            setGoal1(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="goal2"
          placeholder="Meta 2"
          label="Meta 2"
          value={goal2}
          required
          className={css.inputText}
          onChange={(e) => {
            setGoal2(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="goal3"
          placeholder="Meta 3"
          label="Meta 3"
          value={goal3}
          required
          className={css.inputText}
          onChange={(e) => {
            setGoal3(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="goal4"
          placeholder="Meta 4"
          label="Meta 4"
          value={goal4}
          required
          className={css.inputText}
          onChange={(e) => {
            setGoal4(e.target.value);
          }}
          autoComplete="off"
        />
        <div className={css.footer}>
          <button className="saveButton" onClick={() => handleSubmit()}>
            Salvar
          </button>
        </div>
      </form>
    </section>
  );
};

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./AdminPage.module.css";
import Head from "../../Utils/Head";
import { Input } from "../Forms/Input";
import { ClinicContext } from "../../Context/ClinicContext";
import { createPostListsFirebase } from "../../Utils/firebaseClinic";
import OutsideAlerter from "../../Utils/OutsideAlerter";

export const AdminTerActs = () => {
  const { terActsList, setTerActsList, practiceList, setPracticeList } =
    useContext(ClinicContext);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPost, setShowPost] = useState(false);

  function deletePost(post) {
    let updatedterActsList = [...terActsList];
    updatedterActsList = updatedterActsList.filter((p) => p.id !== post.id);

    let updatedPracticeList = [...practiceList];
    updatedPracticeList = updatedPracticeList.filter((p) => p.id !== post.id);

    setTerActsList(updatedterActsList);
    setPracticeList(updatedPracticeList);
    createPostListsFirebase(updatedterActsList, updatedPracticeList);
    alert(post.title + " deleted");
  }

  return (
    <div>
      <Head title="Admin" description={"Admin - Mairini"} />
      <Input
        type="text"
        name="Buscar"
        placeholder="Title"
        label="Search"
        className={css.inputText}
        autoComplete="off"
      />
      <h5>Lista de TerActs</h5>
      {terActsList.map((post) => (
        <div className={css.card} key={post.id}>
          <div className={css.cardTop}>
            <i
              className={`material-icons materialIcon`}
              onClick={() =>
                navigate("/admin/editterAct", {
                  state: {
                    post: "theory",
                    id: post.id,
                    date: post.date,
                    image: post.image,
                    title: post.title,
                    slug: post.slug,
                    content: post.content,
                  },
                })
              }
            >
              edit
            </i>
            <i
              className={`material-icons materialIcon`}
              onClick={() => setShowDeleteModal(post)}
            >
              delete
            </i>
          </div>
          <div className={css.cardBody} onClick={() => setShowPost(post)}>
            <p>{post.title}</p>
          </div>
        </div>
      ))}

      {showPost && (
        <OutsideAlerter setMenuButton={setShowPost}>
          <div className={css.modal}>
            <header className={css.modalHeader}>
              <span
                onClick={() => setShowPost(false)}
                className={`material-icons ${css.materialIcon} ${css.materialButton}`}
              >
                close
              </span>
            </header>
            <div className={css.modalBody}>
              <h3>{showPost.title}</h3>
              <p>{showPost.content}</p>
            </div>
          </div>
        </OutsideAlerter>
      )}
      {showDeleteModal && (
        <OutsideAlerter setMenuButton={setShowDeleteModal}>
          <div className={css.modal}>
            <header className={css.modalHeader}>
              <span
                onClick={() => setShowDeleteModal(false)}
                className={`material-icons ${css.materialIcon} ${css.materialButton}`}
              >
                close
              </span>
            </header>
            <div className={css.modalBody}>
              <h3>Delete {showDeleteModal.title}?</h3>
              <button
                className="deleteButton"
                onClick={(e) => {
                  e.preventDefault();
                  deletePost(showDeleteModal);
                  setShowDeleteModal(false);
                }}
              >
                Deletar
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
};

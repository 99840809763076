import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./UserPage.module.css";
import Head from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";
import {
  getUserByIdFirebase,
  updateUserFirebase,
} from "../../Utils/firebaseClinic";
import { Input } from "../Forms/Input";

export const UserActionPlan = () => {
  const { setSelectedPatient, selectedPatient } = useContext(ClinicContext);
  const [act1, setAct1] = useState("");
  const [act2, setAct2] = useState("");
  const [act3, setAct3] = useState("");
  const [act4, setAct4] = useState("");
  const [act5, setAct5] = useState("");
  const [act6, setAct6] = useState("");
  const [act7, setAct7] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getUserByIdFirebase(params.id).then(function (result) {
      setSelectedPatient(result[0]);
    });
  }, []);

  useEffect(() => {
    if (selectedPatient) {
      selectedPatient.listActions && setAct1(selectedPatient.listActions[0]);
      selectedPatient.listActions && setAct2(selectedPatient.listActions[1]);
      selectedPatient.listActions && setAct3(selectedPatient.listActions[2]);
      selectedPatient.listActions && setAct4(selectedPatient.listActions[3]);
      selectedPatient.listActions && setAct5(selectedPatient.listActions[4]);
      selectedPatient.listActions && setAct6(selectedPatient.listActions[5]);
      selectedPatient.listActions && setAct7(selectedPatient.listActions[6]);
    }
  }, [selectedPatient]);

  function handleSubmit() {
    const listActions = [act1, act2, act3, act4, act5, act6, act7];
    selectedPatient.actionPlan = listActions;

    setSelectedPatient(selectedPatient);
    updateUserFirebase(selectedPatient);
    navigate(`/user/${selectedPatient.id}/today`);
  }

  return (
    <section>
      <Head title="User" description="User - Mairini" />
      <form>
        <h4>Lista de Ações</h4>
        <Input
          type="text"
          name="act1"
          placeholder="Ação 1"
          label="Segunda"
          value={act1}
          required
          className={css.inputText}
          onChange={(e) => {
            setAct1(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="act2"
          placeholder="Ação 2"
          label="Terça"
          value={act2}
          required
          className={css.inputText}
          onChange={(e) => {
            setAct2(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="act3"
          placeholder="Ação 3"
          label="Quarta"
          value={act3}
          required
          className={css.inputText}
          onChange={(e) => {
            setAct3(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="act4"
          placeholder="Ação 4"
          label="Quinta"
          value={act4}
          required
          className={css.inputText}
          onChange={(e) => {
            setAct4(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="act4"
          placeholder="Ação 5"
          label="Sexta"
          value={act5}
          required
          className={css.inputText}
          onChange={(e) => {
            setAct5(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="act4"
          placeholder="Ação 6"
          label="Sábado"
          value={act6}
          required
          className={css.inputText}
          onChange={(e) => {
            setAct6(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="act4"
          placeholder="Ação 7"
          label="Domingo"
          value={act7}
          required
          className={css.inputText}
          onChange={(e) => {
            setAct7(e.target.value);
          }}
          autoComplete="off"
        />
        <div className={css.footer}>
          <button className="saveButton" onClick={() => handleSubmit()}>
            Salvar
          </button>
        </div>
      </form>
    </section>
  );
};

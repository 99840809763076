import dayjs from "dayjs";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// USER

export const getUserFirebase = async (email) => {
  const docRef = collection(db, "users");
  const q = query(docRef, where("email", "==", email));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`user ${email} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getUserByIdFirebase = async (id) => {
  const docRef = collection(db, "users");
  const q = query(docRef, where("id", "==", id));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`user ${id} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getUsersFirebase = async () => {
  const docRef = collection(db, "users");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const updateUserFirebase = async (user) => {
  const userDoc = doc(db, "users", user.id);
  await updateDoc(userDoc, {
    id: user.id,
    displayName: user.displayName,
    email: user.email,
    member: user.member ? user.member : false,
    staff: user.staff ? user.staff : false,
    profilePic: user.profilePic ? user.profilePic : "",
    research: user.research ? user.research : "",
    sex: user.sex ? user.sex : "",
    birthdate: user.birthDate ? user.birthDate : "",
    age: user.age ? user.age : "",
    social: user.social ? user.social : "",
    education: user.education ? user.education : "",
    listProblems: user.listProblems ? user.listProblems : [],
    listGoals: user.listGoals ? user.listGoals : [],
    listValues: user.listValues ? user.listValues : "",
    actionPlan: user.actionPlan ? user.actionPlan : "",
    personalityTraits: user.personalityTraits ? user.personalityTraits : "",
    profilePermissions: user.profilePermissions ? user.profilePermissions : [],
    terAct: user.terAct ? user.terAct : "",
    schedule: user.schedule ? user.schedule : [],
  });
  console.log("user updated");
};

export const getScheduleFirebase = async (userId) => {
  const docRef = doc(db, "schedule", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`schedule loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const createUserScheduleFirebase = async (
  userId,
  periodList,
  scheduleList
) => {
  await setDoc(doc(db, "users", userId), {
    [periodList[0]]: scheduleList[0],
    [periodList[1]]: scheduleList[1],
    [periodList[2]]: scheduleList[2],
    [periodList[3]]: scheduleList[3],
    [periodList[4]]: scheduleList[4],
    [periodList[5]]: scheduleList[5],
    [periodList[6]]: scheduleList[6],
    [periodList[7]]: scheduleList[7],
    [periodList[8]]: scheduleList[8],
  });
  console.log("schedule created");
};

export const getPracticesFirebase = async (pillar) => {
  const docRef = doc(db, "lists", "postLists");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(pillar + `PracticeList loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

// ADMIN

export const createPostListsFirebase = async (terActsList, practiceList) => {
  await setDoc(doc(db, "lists", `postLists`), {
    [`terActsList`]: terActsList,
    [`practiceList`]: practiceList,
  });
  console.log("post created");
};

export const getPostListsFirebase = async () => {
  const docRef = doc(db, "lists", "postLists");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`posts loaded`);
    return docSnap.data();
  } else {
    console.log("No post document");
  }
};

// PROFESSIONAL

export const getProFirebase = async (pro) => {
  const docRef = doc(db, "professionals", pro.id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`pro ${pro.displayName} loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const createProFirebase = async (pro) => {
  await setDoc(doc(db, "professionals", pro.id), {
    clinic: pro.clinic,
    displayName: pro.displayName,
    email: pro.email,
    id: pro.id,
    pro: pro.pro,
    tele: pro.tele,
    schedSun: pro.schedSun,
    schedMon: pro.schedMon,
    schedTue: pro.schedTue,
    schedWed: pro.schedWed,
    schedThu: pro.schedThu,
    schedFri: pro.schedFri,
    schedSat: pro.schedSat,
  });
  console.log(`schedule ${pro.displayName} created`);
};

// APPOINTMENTS

export const getAppointmentsDocDayFirebase = async (day, proEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(
    docRef,
    where("day", "==", dayjs(day).format("DD/MM/YY")),
    where("proEmail", "==", proEmail)
  );
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(
      `appointments ${dayjs(day).format("DD/MM/YY")} - ${proEmail} loaded`
    );
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getAppointmentsDocToConfirmFirebase = async (proEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(
    docRef,
    where("proEmail", "==", proEmail),
    where("status", "==", "não confirmado")
  );
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`unconfirmed appt ${proEmail} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getAppointmentsDocConfirmedFirebase = async (proEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(
    docRef,
    where("proEmail", "==", proEmail),
    where("status", "==", "confirmado")
  );
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`confirmed appt ${proEmail} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getAppointmentsDocCanceledFirebase = async (proEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(
    docRef,
    where("proEmail", "==", proEmail),
    where("status", "==", "cancelado pelo profissional")
  );
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`canceled appt ${proEmail} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getAppointmentsPatFirebase = async (userEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(docRef, where("userEmail", "==", userEmail));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`appointments ${userEmail} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const createAppointmentFirebase = async (appointment) => {
  await setDoc(doc(db, "appointments", appointment.id), {
    id: appointment.id,
    userId: appointment.userId,
    userName: appointment.userName,
    userEmail: appointment.userEmail,
    description: appointment.description,
    proName: appointment.proName,
    proEmail: appointment.proEmail,
    day: appointment.day,
    dtStart: appointment.dtStart,
    dtEnd: appointment.dtEnd,
    status: appointment.status,
    location: appointment.location,
    geo: appointment.geo,
    categories: appointment.categories,
  });
  console.log(`appointment ${appointment.title} created`);
};

export const updateAppointmentFirebase = async (appointment) => {
  await updateDoc(doc(db, "appointments", appointment.id), {
    id: appointment.id,
    userId: appointment.userId,
    userName: appointment.userName,
    userEmail: appointment.userEmail,
    description: appointment.description,
    proName: appointment.proName,
    proEmail: appointment.proEmail,
    day: appointment.day,
    dtStart: appointment.dtStart,
    dtEnd: appointment.dtEnd,
    status: appointment.status,
    location: appointment.location,
    geo: appointment.geo,
    categories: appointment.categories,
  });
  console.log("appointment updated");
};

export const deleteAppointmentFirebase = async (appointment) => {
  await deleteDoc(doc(db, "appointments", appointment.id));
  console.log("appointment deleted");
};

// RECORDS

export const getRecordsFirebase = async (user) => {
  const docRef = collection(db, "records");
  const q = query(docRef, where("userId", "==", user.id));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`records ${user.displayName} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const createRecordFirebase = async (record) => {
  await setDoc(doc(db, "records", record.id), {
    id: record.id,
    date: record.date,
    userId: record.userId,
    proId: record.proId,
    proEmail: record.proEmail,
    proName: record.proName,
    content: record.content,
  });
  console.log(`record created`);
};

// WAITING

export const getWaitingFirebase = async (day) => {
  const docRef = collection(db, "clinic", "waiting", day);
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    console.log(`waiting ${day} loaded`);
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const addWaitingFirebase = async (appointment) => {
  await setDoc(doc(db, "clinic", "waiting", appointment.day, appointment.id), {
    id: appointment.id,
    userId: appointment.userId,
    userName: appointment.userName,
    proEmail: appointment.proEmail,
    day: appointment.day,
    start: appointment.start,
    end: appointment.end,
    status: appointment.status,
    arrived: appointment.arrived,
  });
  console.log(`waiting ${appointment.id} created`);
};

export const delWaitingFirebase = async (appointment) => {
  await deleteDoc(
    doc(db, "clinic", "waiting", appointment.day, appointment.id)
  );
  console.log(`waitingList ${appointment.id} deleted`);
};

import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./UserPage.module.css";
import Head from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";
import {
  getUserByIdFirebase,
  updateUserFirebase,
} from "../../Utils/firebaseClinic";
import { Input } from "../Forms/Input";

export const UserData = () => {
  const { appointmentsDay, setSelectedPatient, selectedPatient } =
    useContext(ClinicContext);
  const [name, setName] = useState(selectedPatient.displayName);
  const [email, setEmail] = useState(selectedPatient.email);
  const [birthDate, setBirthDate] = useState(selectedPatient.birthDate);
  const [age, setAge] = useState(selectedPatient.age);
  const [social, setSocial] = useState(selectedPatient.social);
  const [education, setEducatoion] = useState(selectedPatient.education);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getUserByIdFirebase(params.id).then(function (result) {
      setSelectedPatient(result[0]);
    });
  }, []);

  function handleSubmit() {
    selectedPatient.displayName = name;
    selectedPatient.email = email;
    selectedPatient.birthDate = birthDate;
    selectedPatient.age = age;
    selectedPatient.social = social;
    selectedPatient.education = education;

    setSelectedPatient(selectedPatient);
    updateUserFirebase(selectedPatient);
    navigate(`/user/${selectedPatient.id}/today`);
  }

  return (
    <section>
      <Head title="User" description="User - Mairini" />
      <form>
        <h4>Dados do perfil</h4>
        <Input
          type="text"
          name="displayName"
          placeholder="Nome"
          label="Nome"
          value={name}
          required
          className={css.inputText}
          onChange={(e) => {
            setName(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="email"
          placeholder="Email"
          label="Email"
          value={email}
          required
          className={css.inputText}
          onChange={(e) => {
            setName(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="birthDate"
          placeholder="Data de Nascimento"
          label="Data de Nascimento"
          value={birthDate}
          required
          className={css.inputText}
          onChange={(e) => {
            setBirthDate(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="age"
          placeholder="Idade"
          label="Idade"
          value={age}
          required
          className={css.inputText}
          onChange={(e) => {
            setAge(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="social"
          placeholder="Estado social"
          label="Estado social"
          value={social}
          required
          className={css.inputText}
          onChange={(e) => {
            setSocial(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="education"
          placeholder="Educação"
          label="Educação"
          value={education}
          required
          className={css.inputText}
          onChange={(e) => {
            setEducatoion(e.target.value);
          }}
          autoComplete="off"
        />
        <div className={css.footer}>
          <button className="saveButton" onClick={() => handleSubmit()}>
            Salvar
          </button>
        </div>
      </form>
    </section>
  );
};

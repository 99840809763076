import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { UserStorage } from "./Context/UserContext";
import { ClinicStorage } from "./Context/ClinicContext";
import { RequireAuth } from "./Utils/RequireAuth";
import { Header } from "./Components/Layout/Header";
import { LeftMenu } from "./Components/Sidebar/LeftMenu";
import { Content } from "./Components/Layout/Content";
import { Home } from "./Components/Home";
import { Login } from "./Components/Login/Login";
import { Logout } from "./Components/Login/Logout";
import { LoginCreate } from "./Components/Login/LoginCreate";
import { UserData } from "./Components/UserPage/UserData";
import { Schedule } from "./Components/Schedule/Schedule";
import { UserRecord } from "./Components/UserPage/UserRecord";
import { Prescription } from "./Components/UserPage/Prescription";
import { Certificate } from "./Components/UserPage/Certificate";
import { Users } from "./Components/Users";
import { EditSchedule } from "./Components/Appointments/EditSchedule";
import { Unconfirmed } from "./Components/Appointments/Unconfirmed";
import { Confirmed } from "./Components/Appointments/Confirmed";
import { Canceled } from "./Components/Appointments/Canceled";
import { UserProfile } from "./Components/UserPage/UserProfile";
import { UserSchedule } from "./Components/UserPage/UserSchedule";
import { AdminPage } from "./Components/Admin/AdminPage";
import { NewPractice } from "./Components/Admin/NewPractice";
import { EditPost } from "./Components/Admin/EditPost";
import { AdminUsers } from "./Components/Admin/AdminUsers";
import { AdminPractices } from "./Components/Admin/AdminPractices";
import { RequireStaff } from "./Utils/RequireStaff";
import { UserToday } from "./Components/UserPage/UserToday";
import { EditTerAct } from "./Components/Admin/EditTerAct";
import { AdminTerActs } from "./Components/Admin/AdminTerActs";
import { NewTerAct } from "./Components/Admin/NewTerAct";
import { UserActionPlan } from "./Components/UserPage/UserActionPlan";
import { UserListGoals } from "./Components/UserPage/UserListGoals";
import { UserListProblems } from "./Components/UserPage/UserListProblems";

function App() {
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const toggleMenu = (e) => {
    setIsMenuVisible(!isMenuVisible);
  };

  const SidebarLayout = () => (
    <>
      <Header onMenuClick={toggleMenu} />
      <LeftMenu isMenuVisible={isMenuVisible} />
      <Content
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
    </>
  );

  return (
    <UserStorage>
      <ClinicStorage>
        <Routes>
          <Route path="login/*" element={<Login />} />
          <Route path="signup/" element={<LoginCreate />} />
          <Route path="/logout" element={<Logout />} />
          <Route element={<RequireAuth />}>
            <Route element={<SidebarLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/unconfirmed" element={<Unconfirmed />} />
              <Route path="/confirmed" element={<Confirmed />} />
              <Route path="/canceled" element={<Canceled />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/editschedule" element={<EditSchedule />} />
              <Route path="/users" element={<Users />} />
              <Route path="/user/:id/data" element={<UserData />} />
              <Route path="/user/:id/actionplan" element={<UserActionPlan />} />
              <Route path="/user/:id/listgoals" element={<UserListGoals />} />
              <Route
                path="/user/:id/listproblems"
                element={<UserListProblems />}
              />
              <Route path="/user/:id/today" element={<UserToday />} />
              <Route path="/user/:id/profile" element={<UserProfile />} />
              <Route path="/user/:id/schedule" element={<UserSchedule />} />
              <Route path="/user/:id/record" element={<UserRecord />} />
              <Route path="/user/:id/prescription" element={<Prescription />} />
              <Route path="/user/:id/certificate" element={<Certificate />} />
              <Route element={<RequireStaff />}>
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/admin/users" element={<AdminUsers />} />
                <Route path="/admin/teracts" element={<AdminTerActs />} />
                <Route path="/admin/practices" element={<AdminPractices />} />
                <Route path="/admin/newteract" element={<NewTerAct />} />
                <Route path="/admin/newpractice" element={<NewPractice />} />
                <Route path="/admin/editpost" element={<EditPost />} />
                <Route path="/admin/editteract" element={<EditTerAct />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </ClinicStorage>
    </UserStorage>
  );
}

export default App;

import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";

import { UserContext } from "./UserContext";
import {
  getAppointmentsDocDayFirebase,
  getPostListsFirebase,
  getProFirebase,
  getUsersFirebase,
  getWaitingFirebase,
} from "../Utils/firebaseClinic";

export const ClinicContext = React.createContext();

export const ClinicStorage = ({ children }) => {
  const { userData } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [terActsList, setTerActsList] = useState([]);
  const [practiceList, setPracticeList] = useState([]);
  const [appointmentsDay, setAppointmentsDay] = useState([]);
  const [waitingList, setWaitingList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(dayjs().month());
  const [daySelected, setDaySelected] = useState(dayjs());
  const [proSelected, setProSelected] = useState();
  const [showAppointmentModal, setShowAppointmentModal] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [view, setView] = useState("day");
  const [loadingClinic, setLoadingClinic] = useState(null);

  useEffect(() => {
    if (userData) {
      getUsersFirebase().then(function (result) {
        if (result) {
          setUsers(result);
        }
      });

      getPostListsFirebase().then(function (result) {
        if (result) {
          if (result.terActsList[0] && !terActsList[0]) {
            setTerActsList(result.terActsList);
          }
          if (result.practiceList[0] && !practiceList[0]) {
            setPracticeList(result.practiceList);
          }
        } else {
          console.log("no terActsList");
        }
      });

      // getProFirebase(userData).then(function (result) {
      //   if (result) {
      //     setProSelected(result);
      //   }
      // });
      // getAppointmentsDocDayFirebase(daySelected, userData.email).then(function (
      //   result
      // ) {
      //   if (result) {
      //     setAppointmentsDay(result);
      //   } else {
      //     console.log("no appointmentsDocDay result");
      //   }
      //   setLoadingClinic(false);
      // });
      // getWaitingFirebase(dayjs(daySelected).format("DD-MM-YY")).then(function (
      //   result
      // ) {
      //   if (result) {
      //     setWaitingList(result);
      //   } else {
      //     setWaitingList([]);
      //     console.log("no waiting result");
      //   }
      // });
    }
  }, [userData, daySelected, terActsList, practiceList]);

  return (
    <ClinicContext.Provider
      value={{
        terActsList,
        setTerActsList,
        practiceList,
        setPracticeList,
        users,
        appointmentsDay,
        setAppointmentsDay,
        waitingList,
        setWaitingList,
        selectedPatient,
        setSelectedPatient,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        proSelected,
        setProSelected,
        showAppointmentModal,
        setShowAppointmentModal,
        selectedAppointment,
        setSelectedAppointment,
        view,
        setView,
        loadingClinic,
        setLoadingClinic,
      }}
    >
      {children}
    </ClinicContext.Provider>
  );
};
